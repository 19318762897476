<header>
    <div class="banner--wrap">
        <nav>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">

                        <nav class="navbar navbar-expand-lg navbar-light">
                            <a class="navbar-brand" href="index.html"><img src="assets/logo.png" alt="#" />
                           
                            </a>
                            <button class="navbar-toggler nav-custome1" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle
                                        navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" routerLink="/"> Home</a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" routerLink="about">
                                            About</a>

                                    </li>

                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" routerLink="city">
                                            Outstation Routes
                                        </a>
                                    </li>

                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" routerLink="tariff"> Tariff
                                        </a>

                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" routerLink="contact"> Contact
                                        </a>

                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link btn btn-outline-primary appointment-btn-top"
                                            href="#booking">Book Now</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>

                    </div>
                </div>
            </div>
        </nav>
        <div class="container">
            <div class="banner-slider">
                <div class="banner">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">

                            <div class="main-title text-center">
                                <span>Book One Way Taxi with Global Oneway Cabs</span>
                                <h1>
                                     One Way Cabs @ Just 14 Rs
                                </h1>
                            </div>

                        </div>
                        <div class="nav-right-part">
                            <a class="left" href="tel:{{settings.phoneNo}}">
                                <img src="assets/telephone.png" alt="" srcset="">
                            </a>
                            <a class="right" href="https://wa.me/{{settings.whatsapp}}">
                                <img src="assets/whatsapp.png" alt="" srcset="">
                            </a>
                        </div>
                        <div class="col-12 col-md-6 col-lg-7 d-flex align-items-end">

                            <div class="anim-container flex-fill">
                                <svg class="circle-svg" width="100%" height="100%" viewBox="0 0 754 733" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path class="big-circle" opacity="0.071" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M377 29C563.12 29 714 179.879 714 366C714 552.119 563.12 702.999 377 702.999C190.88 702.999 40 552.119 40 366C40 179.879 190.88 29 377 29Z"
                                        fill="#4D72D0" />
                                    <path class="small-circle" opacity="0.051" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M376.471 120.995C512.043 120.995 621.947 230.898 621.947 366.471C621.947 502.043 512.043 611.946 376.471 611.946C240.898 611.946 130.995 502.043 130.995 366.471C130.995 230.898 240.898 120.995 376.471 120.995Z"
                                        fill="#4D72D0" />
                                </svg>
                                <img src="assets/car-b.png" class="img-fluid" alt="hero" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</header>

<section id="booking" class="about-section">
    <div class="container container-custom">
        <div class="row">
            <div class="col-md-12">

              <app-booking></app-booking>



            </div>
        </div>

        <div class="hide-map">
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                <ng-container *ngIf="showDirection">
                    <agm-direction [origin]="pickupPlace" [destination]="dropPlace" (onResponse)="onResponses($event)">
                    </agm-direction>
                </ng-container>
            </agm-map>
        </div>


        <section class="goSafeHomeWrap fl width100">
            <h3 class="goSafeCardHeader white">Safe Travel</h3>
            <ul class="goSafeCardScroller">
                <li class="goSafeCardWrap">
                    <h3 class="goSafeCardHeader">Introducing Safe Cars</h3>
                    <ul class="goSafeCardList">
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Sanitized
                                environment</span></li>
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Trained staff</span>
                        </li>
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Safe practices</span>
                        </li>
                    </ul>
                    <div class="cardImagePos"><img src="https://gos3.ibcdn.com/go_safe_bus-1589817050.png" alt="" style="width: 62px;"></div>
                </li>
                <li class="goSafeCardWrap">
                    <h3 class="goSafeCardHeader">Sanitized environment</h3>
                    <ul class="goSafeCardList">
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Deep Cleaned
                                Cars</span></li>
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Hand sanitisers
                                provided</span></li>
                    </ul>
                    <div class="cardImagePos"><img src="https://gos3.ibcdn.com/sannitize_env-1590165711.png" alt="" style="width: 62px;"></div>
                </li>
                <li class="goSafeCardWrap">
                    <h3 class="goSafeCardHeader">Trained staff</h3>
                    <ul class="goSafeCardList">
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Driver with
                                masks</span></li>
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Regular temperature
                                checks</span></li>
                    </ul>
                    <div class="cardImagePos"><img src="https://gos3.ibcdn.com/logo_train_staff-1590215654.png" alt="" style="width: 62px;">
                    </div>
                </li>
                <li class="goSafeCardWrap">
                    <h3 class="goSafeCardHeader">Safe Practices</h3>
                    <ul class="goSafeCardList">
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Use hand
                                sanitizer</span></li>
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Ware face mask all
                                time</span></li>
                        <li><i class="icon-reverse-tick ico10 blue-grey"></i><span class="listTxt">Contact
                                Tracing</span></li>
                    </ul>
                    <div class="cardImagePos"><img src="https://gos3.ibcdn.com/logo_safe_practice-1590215777.png" alt="" style="width: 62px;">
                    </div>
                </li>
            </ul>
        </section>




        <!-- features -->
        <section id="knsl-triger-1">

            <div class="container">


                <!-- features card -->
                <div class="knsl-features-card" style="margin-top: 40px;">
                    <h2>Outstation Tariff</h2>

                    <div class="row">
                        <div *ngFor="let car of cars" class="col-6 col-lg-3">

                            <!-- icon box -->
                            <div class="knsl-icon-box">
                                <img src="{{car.image}}" style="max-width: 100px;" alt="icon" class="knsl-mb-10">
                                <h4>{{car.carType}}</h4>
                                <div class="row">
                                    <div class="col">
                                        <h5>ONE WAY</h5>
                                        <p class="rate">

                                            {{(car.carType !== 'Tempo Traveller')? '₹' + car.oneway : 'NA'}}
                                        </p>
                                    </div>
                                    <div class="col">
                                        <h5>ROUND</h5>
                                        <p class="rate">
                                            ₹ {{car.roundtrip}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!-- icon box end -->

                        </div>

                    </div>

                </div>
                <!-- features card end -->

            </div>
        </section>
        <!-- features end -->


        <div class="row space">

            <div class="col-md-4">
                <div class="service-thumbnail d-flex flex-fill">
                    <img src="assets/customer-service.png" alt="#" />
                    <div class="service-thumbnail_text">
                        <h4>24 / 7 Car Support</h4>
                        <p>We Offer 24x7 Pickup Up And Drop Facility In Tamil Nadu To Facilitate The Customers Emergency
                            Needs.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="service-thumbnail d-flex flex-fill">
                    <img src="assets/top-rated.png" class="img-fluid" alt="#" />
                    <div class="service-thumbnail_text">
                        <h4>Top Rated Driver - Partner</h4>
                        <p>All Our Driver trainer To Delivery The Best Experience. </p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="service-thumbnail border-0 d-flex flex-fill">
                    <img src="assets/schedule.png" class="img-fluid" alt="#" />
                    <div class="service-thumbnail_text">
                        <h4>Advance Booking Available</h4>
                        <p>Quick Outstation Ride And OneWay Taxi Service</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">

                <div class="about-video_block">
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <img src="assets/images/about-img.jpg" class="img-fluid w-100" alt="#" />

                        </div>
                        <div class="col-md-12 col-lg-6">
                            <div class="video-play-text">
                                <h2>Short Story About {{settings.siteName}}</h2>
                                <p>
                                    Welcome to {{settings.siteName}} Your trusted journey for a cost saving and safe
                                    journey in and around Tamil Nadu, Chennai, Bangalore, Vellore and Pondy.


                                </p>
                                <p>
                                    Two Way Car Rental is one among the most preferred & trusted taxi/cab services in
                                    Tamilnadu, Bangalore, Chennai, and nearby.
                                </p>
                                <p>
                                    Our journey started in the year 2006 and we are still traveling with satisfied
                                    customers on the roads.
                                </p>
                                <a routerLink="/about">Readmore</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="space light">


    <div *ngFor="let route of  places" class="container container-custom">
        <div class="row">
            <div class="col-md-12">
                <div class="heading-style1">
                    <span>One way drop taxi</span>
                    <h2> {{route.city}} Outstaion drop taxi routes</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">

                <owl-carousel-o [options]="customOptions">
                    <ng-template *ngFor="let place of  route.routes" carouselSlide>
                        <div class="service-slider">
                            <a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-cabs"
                                class="stretched-link">
                                <img src="{{place.image}}" alt="#">
                            </a>

                            <div class="service-block yellow">
                                <a
                                    routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-cabs">
                                    <h3>{{place.origin}} to {{place.destination}}
                                    </h3>
                                </a>
                                <p>
                                    Hire outstation from {{place.origin}} to {{place.destination}} with attractive
                                    rates, clean, well mannered drivers & transparent billing.
                                </p>
                                <a routerLink="/city/{{place.origin | lowercase}}-to-{{place.destination | lowercase}}-outstation-cabs"
                                    class="btn btn-dark">Book Now</a>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>


            </div>
        </div>
    </div>
</section>

<footer>
    <div class="container container-custom">
        <div class="row">
            <div class="col-md-12">
                <div class="copyright">
                    <p>© {{settings.siteName}} 2023 Allright Reserved</p>
                    <p>Developed by <a href="http://www.calvincareinfotech.com">Calvin Care</a></p>
                    <a href="#" id="scroll"><i class="fas fa-angle-double-up"></i></a>
                    
                </div>
            </div>
        </div>
    </div>
</footer>