<div class="container">
    <section class="muse-section pb-4 pb-md-5">

        <div class="row pt-4">
            <div class="col-md-12">
                <img (click)="back()" style="
                width: 30px;
                margin-right: 15px;
            " src="assets/left-arrow.png" alt="" srcset="">
                <h4 class="mb-3">Book Outstation cab from {{origin}} to {{destination}} </h4>
            </div>
            <div class="hide-map">
                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="5">
                    <ng-container *ngIf="showDirection">
                        <agm-direction [origin]="origin" [destination]="destination" (onResponse)="onResponses($event)">
                        </agm-direction>
                    </ng-container>
                </agm-map>
            </div>

            <ng-container *ngIf="showEstimation">
                <app-outstation></app-outstation>
            </ng-container>

            <hr class="bg-gray-300">

            <div class="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div class="col-lg-8">
                    <h1 class="mt-4 mb-4 " style="font-size: 18px;">
                        Why Book a {{origin}} to {{destination}} cab with {{settings.siteName}}?
                    </h1>
                    <p class="big lh-lg text-black-600">
                        With our reliable and convenient {{origin}} to {{destination}} cab service, be assured of on-time pick up and drop while enjoying a comfortable ride with your loved ones in our clean and sanitized cars. You won't have to worry about the hidden charges
                        anymore - our billing is completely transparent. What you see is what you pay. Our courteous and experienced chauffeurs will be your perfect guide and friend on the roads. Unsure about your plan? No worries! We have all been there.
                        Hence, we charge zero cancellation fees.
                    </p>
                </div>
            </div>

            <hr class="bg-gray-300">

            <div class="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <div class="col-lg-8">
                    <img (click)="back()" style="
                    width: 30px;
                    margin-right: 15px;
                " src="assets/left-arrow.png" alt="" srcset="">

                    <h1 class="mt-4 mb-4 " style="font-size: 18px;">
                        Book {{origin}} airport to {{destination}} taxi
                    </h1>
                    <p class="big lh-lg text-black-600">
                        If you are looking for a customised one-day, multi-day or one-way package from {{origin}} to {{destination}}, we have got you covered. For the package-wise car options and their corresponding fare details, visit our {{origin}} to {{destination}} tour
                        package page here. With {{settings.siteName}}, you never have to worry about an empty itinerary again. Reach out to our customer support team for exciting discounts and offers on {{origin}} to {{destination}} cab packages or for
                        customised itineraries.
                    </p>
                </div>
            </div>






        </div>
        <hr class="bg-gray-300">

        <div class="row align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-8">
                <img (click)="back()" style="
                width: 30px;
                margin-right: 15px;
            " src="assets/left-arrow.png" alt="" srcset="">

                <h1 class="mt-4 mb-4 " style="font-size: 18px;">
                    {{origin}} To {{destination}} Taxi For One Way And Round Trip Rides
                </h1>
                <p class="big lh-lg text-black-600">
                    {{settings.siteName}} offers a variety of cab services from {{origin}} airport to {{destination}} at affordable prices. You can book online and choose from a range of AC 4-seater hatchbacks to sedans to 7-seater minivans. Avail our convenient door-to-door
                    service - from airport terminal to your home. Take your journey from {{origin}} airport to {{destination}} with us in our clean, comfortable and sanitized cabs. Now no more queues or waiting for cabs - with {{settings.siteName}}'s
                    on-time pick up and drop service, enjoy a hassle-free and safe ride experience back home.
                </p>
            </div>
        </div>

        <hr class="bg-gray-300">

        <div class="row pt-4">
            <div class="col-md-12">
                <h4 class="mb-3">{{origin}} to {{destination}} outstation cab fare </h4>
            </div>
            <div class="col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <p *ngIf="routeDetails" class="lh-lg mb-4">
                    The distance from {{origin}} to {{destination}} is about {{routeDetails.totalDistance}}km. It take approximately {{routeDetails.totalDuration}} to complete the journey. SEDAN and SUV type of cabs offered by {{settings.siteName}} are well maintained and
                    clean. Our courteous drivers will ensure that your entire journey is comfortable and memorable. {{settings.siteName}} offered world class cab service at reasonable price for all it routes including {{origin}} to {{destination}}. Above
                    is a breakup of the fare charge for you outstation trip from {{origin}} to {{destination}}.
                </p>
            </div>
        </div>



        <hr class="bg-gray-300">

        <div class="row pt-4">
            <div class="col-md-12">
                <h4 class="mb-3">One Way Cabs from {{origin}} to {{destination}} </h4>
            </div>
            <div class="col-md-12 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                <p class="lh-lg mb-4">
                    Make the most outstanding use of the services offered by {{settings.siteName}} in the most excellent prices & go through the comforting and trouble-free journey by hiring {{origin}} to {{destination}} taxi at one way fare. With in excess of a decade of
                    ability in car rental providers, {{settings.siteName}} is the best taxi service provider in the business, if you’re preparation to employ a cab.
                </p>
            </div>
        </div>




    </section>

    <section *ngIf="routeDetails" class="muse-section pb-0 pb-sm-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <h2 class="border-bottom border-gray-200 pb-4 mb-4">Frequently asked questions</h2>
        <div class="row pt-2">
            <div class="col-md-6 mb-2 mb-md-5">
                <h5 class="mb-2 mb-md-3">What is the distance from {{origin}} to {{destination}} by car?</h5>
                <p class="lh-lg">
                    The distance from {{origin}} to {{destination}} by car is about {{routeDetails.totalDistance}}km. More information about our trariff just click <a routerLink="/tariff">here</a>
                </p>
            </div>
            <div class="col-md-6 mb-2 mb-md-5">
                <h5 class="mb-3">What type of vehicle’s are avilable ? </h5>
                <p class="mb-3 lh-lg">
                    We provide vatiry of cars from Sedan to Suv. <br><br> SEDAN :

                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1"> Swift Dzire</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Toyota Etios</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Hyundai Xcent</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Tata Zest</span> <br> <br> SUV :
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Xylo</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Innova</span>
                    <span class="badge badge-warning fs-11 py-1 px-2 ml-1">Tavera</span>
                </p>
            </div>
            <div class="col-md-6 mb-2 mb-md-5">
                <h5 class="mb-2 mb-md-3">Is your service avilable all over Tamilnadu ?</h5>
                <p class="lh-lg">Yes, our service in all over Tamil Nadu.
                </p>
            </div>
        </div>
    </section>


</div>