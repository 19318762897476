<div class="page-content pt-0 pb-0">
    <div class="booking-success-col">
        <div class="back-btn">
            <a routerLink="/" class="back link"><img src="assets/left-arrow-big.svg" alt=""></a>
        </div>
        <div class="booking-middle">
            <div class="booking-middle-inner">
                <span class="tick-icon"><img src="assets/success.png" alt=""></span>
                <div class="success-full-text">
                    <h3>Ride booked Successfully!</h3>
                    <p>Thanks for Choosting <span>{{settings.siteName}}</span> <br> The reservation details have been sent to your email ID and phone. <br> For further details: <a href="tel:{{settings.phoneNo}}">{{settings.phoneNo}}</a> </p>
                </div>
                <div class="btn btn-success"><a routerLink="/" class="btn">Home</a></div>
            </div>
        </div>
    </div>
</div>