import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CARS } from './cars';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { ErrorStateMatcher } from '@angular/material/core';
import { SITE_SETTINGS, TIME, environment } from './settings';
import { LocationStrategy } from '@angular/common';
import firebase from 'firebase';
import { Router, NavigationEnd } from '@angular/router';
import { TaxiService } from './taxi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  title = 'outstationbooking';
  settings = SITE_SETTINGS;
  cars;

  constructor(private taxiService: TaxiService,private router : Router) {

  }

  ngOnInit(): void {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebaseConfig);
    }

    this.router.events.subscribe(x => {
      if(x instanceof NavigationEnd)
      {
        window.scrollTo(0, 0);
      }
    });


    if (this.settings.isCMS) {
      this.getTariff()
    }

  }

  onActivate(event) {
    console.log('called');
    const container = document.querySelector('html');
    container.scrollTop = 0;
 }

  getTariff() {
    firebase.database().ref('tariff').on("value", snap => {
      let data = snap.val();
      let tariff = [];
      if (data) {
        Object.keys(data).forEach(key => {
          if (data[key]) {
            data[key]['keyID'] = key;
            tariff.push(data[key]);
          }
        });
        this.cars = tariff;
        this.taxiService.tariff.next(this.cars);
      }
    });
  }


}
