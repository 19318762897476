<div class="navbar two-action no-hairline">
  <div class="navbar-inner d-flex align-items-center">
    <div class="left">
      <a (click)="back()" class="link icon-only">
        <img src="assets/left-arrow.png" />
      </a>
    </div>
    <div class="sliding custom-title">Enter contact Details</div>
  </div>
</div>

<div class="get-in-touch">
  <form [formGroup]="contactFormGroup" (ngSubmit)="booknow()" id="form">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" formControlName="name" placeholder="Enter your name">
          <i class="far fa-user"></i>
          <div *ngIf="submitted && contactValidation.name.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="contactValidation.name?.errors.required">Name is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Enter your email address" formControlName="email">
          <i class="far fa-envelope"></i>
          <div *ngIf="submitted && contactValidation.email.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="contactValidation.email?.errors.required">Email is required</div>
            <div *ngIf="contactValidation.email?.errors.pattern">Enter valid email address</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <input type="number" class="form-control" placeholder="Your Phone Number" formControlName="phone">
          <i class="fas fa-phone"></i>
          <div *ngIf="submitted && contactValidation.phone.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="contactValidation.phone?.errors.required">Mobile number is required</div>
            <div *ngIf="contactValidation.phone?.errors.pattern">Enter valid mobile number</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Pickup Date</label>
          <input [min]="minDate" (focus)="picker.open()" [matDatepicker]="picker" type="text"
            placeholder="Enter Pickup date" formControlName="pickupDate">
          <span class="input-clear-button"></span>
          <mat-datepicker #picker></mat-datepicker>
          <div *ngIf="submitted && contactValidation.pickupDate.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.pickupDate?.errors.required">Pickup date is required</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Pickup Time</label>
          <input type="text" placeholder="Enter pickup time" formControlName="pickupTime" [ngxTimepicker]="time">
          <span class="input-clear-button"></span>
          <ngx-material-timepicker #time></ngx-material-timepicker>
          <div *ngIf="submitted && contactValidation.pickupTime.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="contactValidation.pickupTime?.errors.required">Pickup time is required</div>
          </div>
        </div>
      </div>
      <div *ngIf="rideDetails && rideDetails.tripType !== 'One Way'" class="col-md-6">
        <div class="form-group">
          <label>Return Date</label>
          <input [min]="tomorrow" type="text" class="form-control" placeholder="Select Date" required=""
            formControlName="returnDate" (focus)="picker1.open()" [matDatepicker]="picker1">
          <mat-datepicker #picker1></mat-datepicker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button (click)="booknow()" class="btn btn-dark">BOOK NOW</button>

      </div>
    </div>
  </form>
</div>

<div *ngIf="bookingDetails" class="col-12">
  <div class="x_car_book_left_siderbar_wrapper float_left">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <!-- Filter Results -->
        <div class="car-filter accordion x_inner_car_acc_accor">
          <!-- Resources -->
          <div class="x_car_access_filer_top_img">
            <h3>{{bookingDetails.carType}}</h3>
            <p> Total distance {{bookingDetails.distance}} KM</p>
          </div>
          <hr>
          <!-- Attributes -->
          <div class="panel panel-default x_car_inner_acc_acordion_padding">
            <div class="panel-heading">
              <h5 class="panel-title"> <a data-toggle="collapse" href="#collapseThree" class="collapse">
                  location</a> </h5>
            </div>
            <div id="collapseThree" class="collapse show">
              <div class="panel-body">
                <div class="x_car_acc_filter_date">
                  <ul>
                    <li>Pick-up <span>{{bookingDetails.origin}}</span>
                    </li>
                    <li>Drop-off <span>{{bookingDetails.destination}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div
            class="panel panel-default x_car_inner_acc_acordion_padding x_car_inner_acc_acordion_padding_last">
            <div class="panel-heading">
              <h5 class="panel-title"> <a data-toggle="collapse" href="#collapsefour" class="collapse">
                  Fare Breakup</a> </h5>
            </div>
            <div id="collapsefour" class="collapse show">
              <div class="panel-body">
                <div class="x_car_acc_filter_date">
                  <ul>
                    <li>Base Fare <span>{{bookingDetails.baseFare}} ₹
                      </span>
                    </li>
                    <li>Driver Batta <span>{{bookingDetails.driverBatta}} ₹ </span>
                    </li>
                    <li>Rate per KM <span>{{bookingDetails.ratePerKM}} ₹</span>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="x_car_acc_filter_bottom_total">
            <ul>
              <li>total <span>{{bookingDetails.tripEstimation | currency: 'INR'}}</span>
              </li>
            </ul>
          </div>

          <hr>
          <!-- Attributes -->
          <div class="panel panel-default x_car_inner_acc_acordion_padding">
            <div class="panel-heading">
              <h5 class="panel-title"> <a data-toggle="collapse" href="#collapseThrees" class="collapse">
                Other information</a> </h5>
            </div>
            <div id="collapseThrees" class="collapse show">
              <div class="panel-body">
                <div class="x_car_acc_filter_date">
                  <ul class="bullet-list-style" style="padding-top: 20px;">

                    <li class="label f-11">Excludes toll costs, parking, Hill Charges, permits and state tax</li>
              
                    <li class="label f-11">₹150/hr will be charged for additional hours</li>
              
                    <li class="label f-11">₹{{bookingDetails.ratePerKM}}/km will be charged for extra km</li>
              
                    <li class="label f-11">Driver allowance per 24 hours - ₹{{bookingDetails.driverBatta}}</li>
              
                    <li class="label f-11">Night time allowance (11:00 PM - 06:00 AM) - ₹200/night</li>
              
                    <li class="label f-11">Extra fare may apply if you don't end trip at {{bookingDetails.destinationCityName}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>