<div class="container">

    <section class="muse-section">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <img (click)="back()" style="
                width: 30px;
                margin-right: 15px;
            " src="assets/left-arrow.png" alt="" srcset="">

                <div class="my-3">
                    <span class="badge badge-primary text-uppercase">About Us</span>
                    <h1 class="display-4 mt-3">
                        {{settings.siteName}} Taxi Provider
                    </h1>
                    <div class="my-4 pb-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        <img src="assets/travel-banner.svg" class="rounded-12 w-100" alt="img">
                    </div>
                    <p class="big mb-4 mb-md-5 pb-lg-5 lh-lg text-black-600 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        {{settings.siteName}} is based in Chennai and our service details are provided as below. we provide return trip also along with one way if needed. We are the leading outdoor taxi service in Chennai by providing best service at the lowest fare.For any
                        queries, you may contact our customer service
                        <a href="tel:{{settings.phoneNo}}"></a>.


                    </p>
                    <p class="big mb-4 lh-lg text-black-600 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                        Relax in comfort as professional drivers take you to your destination on time. Simply call {{settings.siteName}} and all arrangements for the provision of your transport will be managed. Call {{settings.siteName}} at anytime of the day or night, and within
                        minutes, a metered and well appointed AC taxi will arrive to take you comfortably and safely to your destination.
                    </p>

                    <p class="big mb-0 lh-lg text-black-600 aos-init aos-animate">
                        Our services Outstation Rental, Taxi Service,Tourism and Transportation all over south India. As one of the leading travel companies in India, we ensure to simplify traveler’s life by getting them the best outstation cab deals in just a few minutes without
                        even waiting.


                    </p>
                </div>
            </div>
        </div>
    </section>
</div>