export const CARS = [
    {
      carType: 'Sedan',
      'oneway': 14,
      'roundtrip': 13,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: 'ETIOS, SWIFT DZIRE, XCENT, AND SIMILAR',
      passengers: 4,
      image: '/assets/sedan.png'
    },
    {
      carType: 'Etios',
      'oneway': 15,
      'roundtrip': 14,
      'onewayDriverFee': 400,
      'roundTripDriverFee': 400,
      availableCars: ' ETIOS',
      passengers: 4,
      image: '/assets/etios.png'
    },
    {
      carType: 'Suv',
      'oneway': 19,
      'roundtrip': 18,
      'onewayDriverFee': 500,
      'roundTripDriverFee': 500,
      availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
      passengers: 6,
      image: '/assets/suv.png'
    },
    // {
    //   carType: 'Ertiga',
    //   'oneway': 16,
    //   'roundtrip': 14,
    //   'onewayDriverFee': 500,
    //   'roundTripDriverFee': 500,
    //   availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    //   passengers: 5,
    //   image: '/assets/ertiga.jpeg'
    // },
    {
      carType: 'Innova',
      'oneway': 22,
      'roundtrip': 19,
      'onewayDriverFee': 500,
      'roundTripDriverFee': 500,
      availableCars: 'INNOVA',
      passengers: 6,
      image: '/assets/innova.png'
    },
    // {
    //   carType: 'Crysta',
    //   'oneway': 26,
    //   'roundtrip': 16,
    //   'onewayDriverFee': 500,
    //   'roundTripDriverFee': 600,
    //   availableCars: 'INNOVA',
    //   passengers: 7,
    //   image: '/assets/innova-crysta.png'
    // },
    // {
    //   carType: 'Tempo Traveller',
    //   'oneway': 16,
    //   'roundtrip': 19,
    //   'onewayDriverFee': 500,
    //   'roundTripDriverFee': 500,
    //   availableCars: 'TAVERA, XYLO, LODGY AND SIMILAR',
    //   passengers: 12,
    //   image: '/assets/traveller.png'
    // },
  ];