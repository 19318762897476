<div class="container">
    <div class="banner-slider">
        <div class="banner">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-5 d-flex align-items-center">

                    <div class="main-title">
                        <img routerLink="/" style="
                        width: 30px;
                        margin-right: 15px;
                    " src="assets/left-arrow.png" alt="" srcset="">
                        <span>Contact us</span>
                        <h1>
                            Get in touch and will get back to you
                        </h1>
                    </div>

                </div>
                <div class="col-12 col-md-6 col-lg-7 d-flex align-items-end">
                    <div class="anim-container flex-fill">
                        <svg class="circle-svg" width="100%" height="100%" viewBox="0 0 754 733" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="big-circle" opacity="0.071" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M377 29C563.12 29 714 179.879 714 366C714 552.119 563.12 702.999 377 702.999C190.88 702.999 40 552.119 40 366C40 179.879 190.88 29 377 29Z"
                                    fill="#4D72D0" />
                                <path class="small-circle" opacity="0.051" fill-rule="evenodd" clip-rule="evenodd"
                                    d="M376.471 120.995C512.043 120.995 621.947 230.898 621.947 366.471C621.947 502.043 512.043 611.946 376.471 611.946C240.898 611.946 130.995 502.043 130.995 366.471C130.995 230.898 240.898 120.995 376.471 120.995Z"
                                    fill="#4D72D0" />
                            </svg>
                        <img src="assets/images/surprised.png" class="img-fluid" alt="hero" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<section id="booking" class="about-section">
    <div class="container container-custom">
        <div class="row">
            <div class="col-md-12">

                <ul class="booking-form">
                    <li>
                        <h6>Location</h6>
                        <p class="lh-lg">No.133/1, M.M. Complex,
                            Annasalai Road,
                            Pon- pudupatti (po),
                            Ponnamaravathi (tk),
                            Pudukkottai (dt), 
                            Pincode - 622407</p>
                    </li>
                    <li>
                        <h6>Email</h6>
                        <p class="lh-lg"><a href="mailto:{{settings.enquiryEmail}}" class="text-secondary">{{settings.enquiryEmail}}</a></p>
                    </li>
                    <li>
                        <h6 class="mb-3">Phone</h6>
                        <p class="mb-2"><a href="tel:{{settings.phoneNo}}" class="text-secondary">{{settings.phoneNo}}</a></p>
                    </li>

                    <li>
                        <h6 class="mb-3">Whatsapp</h6>
                        <p class="mb-2"><a href="https://wa.me/{{settings.whatsapp}}" class="text-secondary">{{settings.whatsapp}}</a></p>
                    </li>

                </ul>

            </div>
        </div>
    </div>
</section>