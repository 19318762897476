<div class="navbar two-action no-hairline">
    <div class="navbar-inner d-flex align-items-center">
        <div class="left">
            <a *ngIf="!hideBackBtn" (click)="back()" class="link icon-only">
                <img src="assets/left-arrow.png" />
            </a>
        </div>
        <div class="sliding custom-title">Select your preffered car & trip</div>
    </div>
</div>


<div class="page-content pt-0">
    <div class="container">

        <!-- Search tag -->
        <div class="search-tag">
            <h4>I'm Looking for ?</h4>

            <div class="wo-radioholder">

                <div class="pricing-tab">
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" role="tab" aria-controls="pills-home" aria-selected="true" (click)="selectTrip('One Way')">One
                                Way</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" (click)="selectTrip('Round Trip')" role="tab" aria-controls="pills-profile" aria-selected="false">Round Trip</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- /Search tag -->


        <div *ngIf="routeDetails" class="facilities green">
            <div>
                <span>{{routeDetails.totalDuration}} {{tripType}} of about {{(tripType) ==='One Way' ?
                    routeDetails.totalDistance : routeDetails.totalDistance * 2 }} km</span> <br> ✔ No pre-payment required <br> ✔ Free cancellation
            </div>
        </div>



        <!-- focused -->
        <div *ngIf="estimations.length">
            <div *ngFor="let car of estimations" class="bg-wrap">

                <ng-container *ngIf="car.carType !=='Tempo Traveller' && tripType === 'One Way' || tripType === 'Round Trip'">
                    <div class="wrap">
                        <div class="left">
                            <img src="{{car.image}}" class="img-fluid" alt="#">
                            <h4>{{car.carType}}</h4>
                            <span>({{car.passengers}} + 1)</span>
                        </div>
                        <div class="right">
                            <h5>Trip Cost: <span>₹ {{(tripType) ==='One Way' ? car.onewayTripEstimation :
                                    car.roundTripEstimation}} </span> for {{(tripType) ==='One Way' ? car.distance + 'km' : car.distance * 2 + 'km'}} </h5>

                            <p class="doc-department">
                                Per km ₹<strong>{{(tripType) ==='One Way' ? car.onewayRatePerKM : car.roundTripRatePerKM}}</strong>
                            </p>
                            <p> Avilable Cars: <span class="cap">{{car.availableCars}}</span> </p>
                        </div>
                    </div>

                    <div class="clinic-btn">
                        <a class="apt-btn" href="tel:{{site_settings.phoneNo}}">
                            <img src="assets/mobile-phone.png" alt="" srcset="">
                        </a>
                        <a class="apt-btn" href="tel:{{site_settings.phoneNo}}">
                            <img src="assets/whatsapp.png" alt="" srcset="">
                        </a>

                        <a class="btn btn-success" (click)="proceedToBooking(car)">Book
                            Now</a>
                    </div>
                </ng-container>


            </div>
        </div>
    </div>
</div>