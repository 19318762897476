import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CARS } from 'src/app/cars';
import { OutstationService } from 'src/app/outstation.service';
import { SITE_SETTINGS } from 'src/app/settings';
import { TaxiService } from 'src/app/taxi.service';

@Component({
  selector: 'app-outstation',
  templateUrl: './outstation.component.html',
  styleUrls: ['./outstation.component.css']
})
export class OutstationComponent implements OnInit {

  cars = CARS;
  estimations = [];
  routeDetails;
  tripType = 'One Way';
  site_settings = SITE_SETTINGS;
  hideBackBtn = false;

  constructor(private taxiservice: TaxiService, private router: Router, private outstation: OutstationService) { }

  ngOnInit(): void {
    this.outstation.hideBackBtn.subscribe(res => {
      if(res) {
        this.hideBackBtn = res;
      }
    })

    this.taxiservice.routeDetails.subscribe(route => {
      this.routeDetails = route;
      this.getEstimation(route);
    })
  }

  getEstimation(routes) {

    let distance = (routes.totalDistance <= 130) ? routes.totalDistance = 130 : routes.totalDistance;

    this.cars.forEach(car => {
      car['onewayTripEstimation'] = car.oneway * distance + car.onewayDriverFee;
      car['roundTripEstimation'] =  car.roundtrip * distance * 2 + car.roundTripDriverFee;
      car['onewayDriverBatta'] = car.onewayDriverFee;
      car['roundTripDriverBatta'] = car.roundTripDriverFee;
      car['onewayRatePerKM'] = car.oneway;
      car['roundTripRatePerKM'] = car.roundtrip;
      car['distance'] = routes.totalDistance;
      car['duration'] = routes.totalDuration;
      car['onewayBaseFare'] = car.oneway * distance;
      car['roundTripBaseFare'] = car.roundtrip * distance * 2;
      car['triptype'] = routes.triptype;
      this.estimations.push(car);
    });
  }

  selectTrip(type) {
    this.tripType = type;
    console.log(this.tripType)
  }

  back() {
    this.router.navigateByUrl('/');
  }

  proceedToBooking(selectedCar) {
    this.router.navigateByUrl('/booking',{ skipLocationChange: true });

    let rideDetails = {
      ...this.routeDetails,
      tripEstimation: (this.tripType === 'One Way') ? selectedCar.onewayTripEstimation : selectedCar.roundTripEstimation,
      ratePerKM: (this.tripType === 'One Way') ? selectedCar.onewayRatePerKM : selectedCar.roundTripRatePerKM,
      driverBatta: (this.tripType === 'One Way') ? selectedCar.onewayDriverBatta : selectedCar.roundTripDriverBatta,
      baseFare: (this.tripType === 'One Way') ? selectedCar.onewayBaseFare : selectedCar.roundTripBaseFare,
      tripType: this.tripType,
      carType: selectedCar.carType,
      distance: (this.tripType === 'One Way') ? selectedCar.distance : selectedCar.distance * 2
    }

    this.taxiservice.rideDetails.next(rideDetails);
    this.scrollToSectionHook();
    console.log(rideDetails);
  }

  private scrollToSectionHook() {
    const stepElement = document.querySelector('body');
    stepElement.scrollTop = 0;
  }

}
