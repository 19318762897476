<mat-horizontal-stepper #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="editable">
        <div class="login-root">
            <div class="box-root flex-flex flex-direction--column" style="flex-grow: 1;">

                <div class="box-root flex-flex flex-direction--column" style="flex-grow: 1">

                    <div class="formbg-outer">
                        <div class="formbg">
                            <div class="formbg-inner padding-horizontal--48">
                                <form [formGroup]="firstFormGroup" id="stripe-login">

                                    <mat-radio-group style="
                                  
                                    display: inline-block;
                                " aria-label="Select an option" formControlName="tripType">
                                        <mat-radio-button value="One Way"
                                            style="margin-right: 10px;">One Way
                                        </mat-radio-button>
                                        <mat-radio-button value="Round Trip">Round Trip
                                        </mat-radio-button>
                                    </mat-radio-group>
                                    <div class="grid--50-50">
                                        <div class="field padding-bottom--24">
                                            <input placeholder="Full name"
                                                formControlName="name">
                                            <mat-error
                                                *ngIf="submitted && contactValidation['name'].errors">
                                                Name is <strong>required</strong>
                                            </mat-error>
                                        </div>
                                        <div class="field padding-bottom--24">
                                            <input placeholder="Mobile number"
                                                formControlName="phone">

                                            <mat-error
                                                *ngIf="submitted && contactValidation['phone']?.errors">
                                                Enter valid <strong> Mobile number </strong>
                                            </mat-error>
                                        </div>
                                    </div>
                                    <!-- 
                                    <div class="field padding-bottom--24">
                                        <div class="grid--50-50">
                                            <label for="fullName">Email Id(optional)
                                            </label>
                                        </div>
                                        <input matInput placeholder="Email id"
                                            formControlName="email">

                                        <mat-error
                                            *ngIf="submitted && contactValidation.email?.errors?.pattern">
                                            Enter valid <strong>Email ID</strong>
                                        </mat-error>
                                    </div> -->
                                    <div class="grid--100">
                                        <div class="field padding-bottom--24">

                                            <input placeholder="Pickup location" #origin>
                                        </div>
                                        <div class="field padding-bottom--24">
                                            <input placeholder="Drop location" #destination>
                                        </div>
                                    </div>
                                    <agm-map class="hide" [latitude]="latitude"
                                        [longitude]="longitude" [zoom]="5">
                                        <ng-container *ngIf="showDirection">
                                            <agm-direction [origin]="pickupPlace"
                                                [destination]="dropPlace"
                                                (onResponse)="onResponses($event)">
                                            </agm-direction>
                                        </ng-container>
                                    </agm-map>
                                    <div class="grid--50-50">
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Pickup Date</label>

                                            <input formControlName="pickupDate"
                                                placeholder="Pickup date"
                                                formControlName="pickupDate" [min]="minDate"
                                                (click)="picker.open()"
                                                [matDatepicker]="picker" readonly>
                                            <mat-datepicker #picker></mat-datepicker>
                                            <mat-error
                                                *ngIf="submitted && contactValidation['pickupDate']?.errors">
                                                Pickup Date is <strong>required</strong>
                                            </mat-error>
                                        </div>
                                        <div class="field padding-bottom--24">
                                            <label for="fullName">Pickup Time</label>
                                            <mat-select class="form-control time-select"
                                                formControlName="pickupTime"
                                                placeholder="Time">
                                                <mat-option *ngFor="let time of times"
                                                    [value]="time">
                                                    {{time}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="submitted && contactValidation['pickupTime']?.errors">
                                                Pickup Time is <strong>required</strong>
                                            </mat-error>
                                        </div>
                                    </div>


                                    <div *ngIf="contactValidation['tripType'].value === 'Round Trip'"
                                        class="field padding-bottom--24">
                                        <div class="grid--50-50">
                                            <label for="fullName">Return Date</label>
                                        </div>
                                        <input [min]="contactValidation['pickupDate'].value"
                                            (focus)="picker1.open()"
                                            [matDatepicker]="picker1" matInput
                                            placeholder="Retun date"
                                            formControlName="returnDate" readonly>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </div>
                                    <div class="clear"></div>
                                    <label style="margin-bottom: 0;">Please select your cab
                                        type</label>
                                    <div class="carbox">
                                        <div *ngFor="let car of cars" class="col">
                                            <div class="cars-item"
                                                (click)="selectedCarType(car)">
                                                <label>{{
                                                    contactValidation['tripType'].value
                                                    !== 'Round Trip' ?
                                                    car.oneway : car.roundtrip}} ₹
                                                    / Km</label>
                                                <img *ngIf="car.carType !== selectedCarName"
                                                    src="{{car.image}}">
                                                <img *ngIf="car.carType === selectedCarName"
                                                    src="{{car.activeImage}}">
                                                <label>{{car.carType}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field padding-bottom--24">
                                        <button class="booking" (click)="requestAride()">Get
                                            Estimate</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </mat-step>
    <mat-step>

        <div *ngIf="show" class="estimate-wrap">
            <p>Trip estimation for {{bookingDetails.originCityName}} to
                {{bookingDetails.destinationCityName}}</p>
            <strong style="
            display: block;
            font-size: 32px;
            color: #fe0101;
            margin: 20px 0;
        ">
                <span>Fare</span> {{bookingDetails.tripEstimation | currency:'INR' }}
            </strong>
            <b>Total Distance: </b>{{bookingDetails.distance}} KM <br>
            <b>Total Duration:</b> {{bookingDetails.totalDuration}} <br>
            <b>Selected Car:</b> {{bookingDetails.carType}} <br>
            <b>Driver Batta:</b> ₹{{bookingDetails.driverBatta}} <span *ngIf="days"> for
                {{this.days}} Days </span>
            <br>

            <em style="
            margin: 20px 0;
            display: inline-block;
        ">Note: Above estimation is exclusive of Toll Gate and State Permit Etc</em>
            <div class="col-12 text-center mt-3 mb-3">
                <button (click)="confirm()" mat-raised-button color="primary">Confirm
                    Booking</button>
            </div>
        </div>

    </mat-step>
    <mat-step>
        <div class="success">
            <div style="text-align: center;" *ngIf="bookingDetails">
                <h4>Ride Booked Successfully</h4>
                <img src="https://images.ctfassets.net/509kpi6dw56l/4zLD6wiohRdGrrQtWGD0KO/49149fa4ce096618802c2c2b0fee57a1/success.gif?h=250"
                    alt="" srcset="">
                <p>
                    Thanks for Choosing {{settings.siteName}}, reservation details have been
                    sent
                    to your email id & phone.
                </p>
                <div style="text-align: center;">
                    <button (click)="home()" mat-raised-button color="primary">Home</button>
                </div>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>